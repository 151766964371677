import React, { useEffect } from 'react';
import "./flight.scss";
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
});

function Flight({ flight, setCurrent, getCurrent, direction, selected }) {
  const clickFunction = (e) => {
    e.preventDefault();
    if (getCurrent.key === flight.key) {
      setCurrent({})
    } else {
      setCurrent(flight)
    }
  }


  return (
    <div className="flight-search-result" onClick={clickFunction}>
      {flight.segments.map((segment, index) => {
        const durationArray = segment.duration.replace('PT', '').replace('H', ':').replace('M', '').split(':');

        return (
          <div className="left">
            <div className="segment">
              <img className="segment-airline-logo" src={`https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/${segment.marketing_carrier.iata_code}.svg`} alt={segment.marketing_carrier.iata_code} />
              <div className="flight-search-result-airline">
                <p>{segment.marketing_carrier.name} #<b>{segment.operating_carrier_flight_number}</b></p>
              </div>
            </div>
            <div className="times">
              <div className="flight-search-result-time">
                <time>{new Date(segment.departing_at).toLocaleTimeString('en-us', { hour: "numeric", minute: "numeric" })}</time>
                <small>{new Date(segment.arriving_at).toLocaleDateString('en-us')}</small>
              </div>

              <div className="dashed" >
                <div className="plane">
                  <img className="plane-icon" src="black-planes.svg" />
                  <p>{durationArray[0]}:{durationArray[1].padStart(2, 0)}</p>
                </div>
              </div>

              <div className="flight-search-result-time">
                <time>{new Date(segment.arriving_at).toLocaleTimeString('en-us', { hour: "numeric", minute: "numeric" })}</time>
                <small>{new Date(segment.arriving_at).toLocaleDateString('en-us')}</small>
              </div>

            </div>
            <div className="destinations">

              <div className="flight-search-result-airport">
                <p className="iata">{segment.origin.iata_code}</p>
                <p className="name">{segment.origin.name}</p>
              </div>
              <div className="flight-search-result-airport">
                <p className="name">{segment.destination.name}</p>
                <p className="iata">{segment.destination.iata_code}</p>
              </div>

            </div>
          </div>

        )
      })}
      {selected ? (null) : (
        <div className="right">
          <div className="row">
            <div className="column">
              <p className="type">{direction === 'outbound' ? 'Roundtrip from' : 'Roundtrip total'}</p>
              <p className="price">{formatter.format(flight.price)}</p>
            </div>


          </div>
          {/* <div className="flight-search-result-price">
          {formatter.format(Math.ceil(flight.total_amount))}
        </div>
        <a href={flight.bookingData.link} target="_blank" className="btn btn--primary">Book Now</a> */}
        </div>
      )}
    </div>
  )
}

export default Flight;
