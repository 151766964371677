import app from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    /* Helper */

    this.serverValue = app.database.ServerValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth = app.auth();
    this.recaptchaVerifier = app.auth.RecaptchaVerifier;
    this.db = app.database();

    /* Social Sign In Method Provider */

    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();
    this.twitterProvider = new app.auth.TwitterAuthProvider();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () =>
    this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () =>
    this.auth.signInWithPopup(this.facebookProvider);

  doSignInWithTwitter = () =>
    this.auth.signInWithPopup(this.twitterProvider);

  doSignInWithPhoneNumber = (phoneNumber, applicationVerifier) =>
    this.auth.signInWithPhoneNumber(phoneNumber, applicationVerifier);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
    });

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .once('value')
          .then(async (snapshot) => {
            let dbUser = snapshot.val();

            if (!dbUser) {
              await this.user(authUser.uid).set({
                username: authUser.displayName,
                email: authUser.email,
                phoneNumber: authUser.phoneNumber,
                roles: {},
              });
            }

            dbUser = (await this.user(authUser.uid).once('value')).val();

            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = {};
            }

            const invites = await this.invites().orderByChild('phoneNumber').equalTo(authUser.phoneNumber).once('value').then(snapshot => snapshot.val());

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              phoneNumber: authUser.phoneNumber,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              isInvited: invites !== null,
              inviteData: Object.values(invites || {})[0] || {},
              inviteId: Object.keys(invites || {})[0] || null,
              ...dbUser,
            };

            console.log(authUser);

            next(authUser);
          });
      } else {

        fallback();
      }
    });

  // *** User API ***

  user = uid => this.db.ref(`users/${uid}`);

  users = () => this.db.ref('users');

  // *** Message API ***

  message = uid => this.db.ref(`messages/${uid}`);

  messages = () => this.db.ref('messages');

  // *** Message API ***

  invite = uid => this.db.ref(`invites/${uid}`);

  invites = () => this.db.ref('invites');
}

export default Firebase;
