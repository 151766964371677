import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import "./navigation.scss"
import { AuthUserContext } from '../Session';
import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import MobileNav from './MobileNav';
import { MenuContext } from '../../context/MenuContext';

const Navigation = () => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <NavigationAuth authUser={authUser} />
      ) : (
        <NavigationNonAuth />
      )
    }
  </AuthUserContext.Consumer>
);

const Hamburger = () => {
  return (
    <>
      <div className="burger burger1" />
      <div className="burger burger1" />
      <div className="burger burger1" />

      <style jsx>{`
        .hamburger{
          width: 2rem;
          height: 2rem;
          display: flex;
          justify-content: space-around;
          flex-flow: column nowrap;
          z-index: 10;
        }

        .burger{
          width: 2rem;
          height: 0.25rem;
          border-radius: 10px;
          background-color: white;
          transform-origin: 1px;
          transition: all 0.3s linear;
        }
      `}</style>
    </>


  )

}

const NavigationAuth = ({ authUser }) => {

  const {hamburgerOpen, setHamburgerOpen} = useContext(MenuContext);

  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  }

  return (
    <>
      <div className='navContainer'>
        <Link to={ROUTES.HOME}>
          <img src="/logo.svg" alt="Jack & Camille" />
        </Link>

        <nav className="nav">
          <ul>
            <li>
              <Link to={ROUTES.ITINERARY}>Itinerary</Link>
            </li>

            <li>
              <Link to={ROUTES.FLIGHTS}>Flights</Link>
            </li>
            <li>
              <Link to={ROUTES.HOTEL}>Hotel</Link>
            </li>
            <li>
              <Link to={ROUTES.RSVP}>RSVP</Link>
            </li>


            {!!authUser.roles[ROLES.ADMIN] && (
              <li>
                <Link to={ROUTES.ADMIN}>Admin</Link>
              </li>
            )}
            {/* <li>
          <SignOutButton />
        </li> */}
          </ul>
        </nav>
        <Link to={ROUTES.RSVP} >
          <img className="account" src="/account.svg" alt="RSVP" />
        </Link>

        <div className="hamburger" onClick={toggleHamburger}>
          <Hamburger />
        </div>


      </div>

      <div className="nav-placement">
        {hamburgerOpen ? <MobileNav /> : ""}
      </div>



    </>
  )
};

const NavigationNonAuth = () => (
  <div />
);

export default Navigation;
