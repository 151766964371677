import React, { Component } from 'react';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';

import InviteList from './InviteList';
import PhoneInputWithCountrySelect from 'react-phone-number-input';
import './invites.scss'

import axios from 'axios';

/* import Telnyx from 'telnyx';
const texter = Telnyx('KEY0180480FA6A158CB66E17757EB42FDD2_S4iKdiJAHkH5ff6vQkbVGa'); */

class Invites extends Component {
  constructor(props) {
    super(props);

    this.state = {
      phoneNumber: '',
      name: '',
      sebet: false,
      loading: false,
      invites: [],
      limit: 500,
    };
  }

  componentDidMount() {
    this.onListenForInvites();
  }

  onListenForInvites = () => {
    this.setState({ loading: true });

    this.props.firebase
      .invites()
      .orderByChild('createdAt')
      .limitToLast(this.state.limit)
      .on('value', snapshot => {
        const inviteObject = snapshot.val();

        if (inviteObject) {
          const inviteList = Object.keys(inviteObject).map(key => ({
            ...inviteObject[key],
            uid: key,
          }));

          this.setState({
            invites: inviteList,
            loading: false,
          });
        } else {
          this.setState({ invites: null, loading: false });
        }
      });
  };

  componentWillUnmount() {
    this.props.firebase.invites().off();
  }

  onChangeName = event => {
    this.setState({ name: event.target.value });
  };

  onChangePhoneNumber = number => {
    this.setState({ phoneNumber: number });
  };

  onCreateInvite = (event, authUser, sendMessage = false) => {
    this.props.firebase.invites().push({
      fullName: this.state.name,
      phoneNumber: this.state.phoneNumber,
      sebet: this.state.sebet,
      userId: authUser.uid,
      createdAt: this.props.firebase.serverValue.TIMESTAMP,
    });

    if (sendMessage) {
      const url = `https://api.nizucwedding.com/texter`;
      axios.post(url, {
        phoneNumber: this.state.phoneNumber,
        name: this.state.name,
      })
    }

    /* texter.messages.create({
      'from': '+12029333222',
      'to': this.state.phoneNumber,
      'text': 'You have been invited to join the party!',
    }).then(function(response){
      console.log(response.data);
    }).catch(function(error){
      console.log(error);
    }); */

    this.setState({ name: '', phoneNumber: '', sebet: false });

    event.preventDefault();
  };

  onEditInvite = (invite, text, phoneNumber, attending, rooms, arrivalDate, departureDate, flightInfo, sebet, nizucReservation) => {
    const { uid, ...inviteSnapshot } = invite;

    /* if (inviteSnapshot.phoneNumber !== phoneNumber) {
      const url = `https://api.nizucwedding.com/texter`;
      axios.post(url, {
        phoneNumber: this.state.phoneNumber,
        name: this.state.name
      })
    } */

    const editData = {
      text,
      phoneNumber,
      attending,
      rooms,
      arrivalDate,
      departureDate,
      flightInfo,
      sebet,
      nizucReservation,
    }

    const filteredEditData = JSON.parse(JSON.stringify(editData));

    this.props.firebase.invite(invite.uid).set({
      ...inviteSnapshot,
      ...filteredEditData,
      editedAt: this.props.firebase.serverValue.TIMESTAMP,
    });
  };

  onRemoveInvite = uid => {
    this.props.firebase.invite(uid).remove();
  };

  onNextPage = () => {
    this.setState(
      state => ({ limit: state.limit + 100 }),
      this.onListenForInvites,
    );
  };

  render() {
    const { phoneNumber, name, invites, loading } = this.state;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div>
            <form
              onSubmit={event =>
                this.onCreateInvite(event, authUser)
              }
              className="invite-form"
            >
              <label>
                Party Name:
                <input
                  type="text"
                  value={name}
                  onChange={this.onChangeName}
                />
              </label>

              <PhoneInputWithCountrySelect
                placeholder="Enter phone number"
                defaultCountry="US"
                onChange={this.onChangePhoneNumber}
                value={phoneNumber}
              />

              <label>
                <input
                  type="checkbox"
                  checked={this.state.sebet}
                  onChange={e => this.setState({ sebet: e.target.checked })}
                />
                <span>Invited to Sebet?</span>
              </label>
              <button type="submit">Invite</button>
              <button type="button" onClick={e => {
                e.preventDefault()
                this.onCreateInvite(e, authUser, true)
                }}>Invite & Send Text</button>
            </form>

            {!loading && invites && (
              <button type="button" onClick={this.onNextPage}>
                More
              </button>
            )}

            {loading && <div>Loading ...</div>}

            {invites && (
              <InviteList
                authUser={authUser}
                invites={invites}
                onEditInvite={this.onEditInvite}
                onRemoveInvite={this.onRemoveInvite}
              />
            )}

            {!invites && <div>There are no invites ...</div>}
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(Invites);
