import React, { Component, useEffect, useState } from 'react';
import { compose } from 'recompose';

import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from '../Session';
import { withFirebase } from '../Firebase';
import SignOutButton from '../SignOut';
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Prefooter from '../Prefooter';
import './rsvp.scss'

const RSVPForm = (props) => {
  const { authUser } = props;

  const [email, setEmail] = useState(authUser.inviteData.email || "")
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const [fullName, setFullName] = useState(authUser.inviteData.fullName || "")

  const [attending, setAttending] = useState(authUser.inviteData.attending || "")

  const [rooms, setRooms] = useState(authUser.inviteData.rooms || 0)

  const [arrivalDate, setArrivalDate] = useState(authUser.inviteData.arrivalDate ? new Date(authUser.inviteData.arrivalDate) : "")

  const [departureDate, setDepartureDate] = useState(authUser.inviteData.departureDate ? new Date(authUser.inviteData.departureDate) : "")

  const [flightInfo, setFlightInfo] = useState(authUser.inviteData.flightInfo || "")

  const [formSubmitted, setFormSubmitted] = useState(false)


  const onSubmit = event => {
    event.preventDefault();
    setFormSubmitted(true);
    console.log(attending )
    props.firebase
      .invite(authUser.inviteId)
      .set({
        ...authUser.inviteData,
        fullName,
        email,
        attending,
        rooms,
        arrivalDate: arrivalDate ? arrivalDate.toString() : '',
        departureDate: departureDate ? departureDate.toString() : '',
        flightInfo
      })
      .then(() => {
        console.log("success");
      }
      )
      .catch(error => {
        console.log(error);
      }
      );
  };

  useEffect(() => {
    setTimeout(() => { 
      setFormSubmitted(false);
    }, 3000);
  }, [formSubmitted]);

  return (
    <>
      <div className="form-container" style={{ backgroundImage: `url(/rec1.svg)` }}>
        <div className="gold-pill first">RSVP</div>
        <h1>We look forward to celebrating with you!</h1>


        <form className="h-form" onSubmit={onSubmit}>
          <label htmlFor="fname">Please enter your full name: </label>
          <input type="text" id="fname" name="fname" value={fullName} onChange={e => setFullName(e.target.value)} /><br></br>

          <label htmlFor="lname">Your email:</label>
          <input type="text" id="lname" name="lname" value={email} onChange={handleEmailChange} /><br></br>

          <label className='radio-title'>Will we see you there?</label>
          <div className="radio-group">
            <label>
              <input type="radio" value="Yes" name="choice" checked={attending === 'Yes'} onChange={e => e.target.checked && setAttending('Yes')} />
              <span>Yes</span>
            </label>
            <label>
              <input type="radio" value="No" name="choice" checked={attending === 'No'} onChange={e => e.target.checked && setAttending('No')} />
              <span>No</span>
            </label>
          </div>

          <label htmlFor="rooms" className='radio-title'>How many rooms will you be reserving?</label>
          <div className="select-group">
            <select name="rooms" className="select" id="rooms" onChange={e => { setRooms(e.target.value) }} defaultValue={rooms}>
              <option value="0">Select</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </div>

          <label htmlFor="arrivalDate" className='radio-title'>What date are you arriving on?</label>
          <ReactDatePicker id="arrivalDate" selected={arrivalDate} value={arrivalDate} onChange={(date) => setArrivalDate(date)} minDate={new Date(2023, 10, 15)} maxDate={new Date(2023, 11, 5)}  />

          <label htmlFor="departureDate" className='radio-title'>What date are you departing on?</label>
          <ReactDatePicker id="departureDate" selected={departureDate} value={departureDate} onChange={(date) => setDepartureDate(date)} minDate={new Date(2023, 10, 15)} maxDate={new Date(2023, 11, 5)} />

          <label htmlFor="rsvp-flightinfo" className='radio-title'>What is your flight information?</label>
          <textarea id="rsvp-flightinfo" resize="none" value={flightInfo} onChange={e => setFlightInfo(e.target.value)}></textarea>

          <button className="button" type="submit" disabled={formSubmitted}>Submit</button>
          {formSubmitted && <p>Thank you for submitting your RSVP!</p>}
        </form>
      </div>

      <Prefooter />
    </>
  )
}


const RSVP = (props) => {
  const { firebase } = props;
  return (<AuthUserContext.Consumer>
    {authUser => {
      return (
        <div className="container">
          <RSVPForm authUser={authUser} firebase={firebase} />
        </div>
      )
    }}
  </AuthUserContext.Consumer>
  )
};

const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(RSVP);
