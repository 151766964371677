import React from 'react';
import { Link } from 'react-router-dom';
import "./prefooter.scss"
import * as ROUTES from '../../constants/routes';

const Prefooter = () => (
  <div className="prefooter">
    <div className="prefooter-container">
      <div className="prefooter-tile">
        <Link to={ROUTES.ITINERARY}>
          <div className="icon">
            <img className="time" src="/itinerary.svg" />
          </div>

          <div className="row">
            <p>Itinerary</p>
            <img src="/arrows.svg"></img>
          </div>
        </Link>
      </div>
      <div className="prefooter-tile">
        <Link to={ROUTES.FLIGHTS}>
          <div className="icon">
            <img className="time" src="/plane.svg" />
          </div>

          <div className="row">
            <p>Flights</p>
            <img src="/arrows.svg"></img>
          </div>
        </Link>
      </div>
      <div className="prefooter-tile">
        <Link to={ROUTES.HOTEL}>
          <div className="icon">
            <img className="time" src="/hotel.svg" />
          </div>

          <div className="row">
            <p>Hotels</p>
            <img src="/arrows.svg"></img>
          </div>
        </Link>
      </div>
      <div className="prefooter-tile">
        <Link to={ROUTES.RSVP}>
          <div className="icon">
            <img className="time" src="/rsvp.svg" />
          </div>

          <div className="row">
            <p>RSVP</p>
            <img src="/arrows.svg"></img>
          </div>
        </Link>
      </div>
    </div>
  </div>
);

export default Prefooter;