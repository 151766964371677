import React, { useState } from 'react';
import "./itinerary.scss";
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import Prefooter from '../Prefooter';
import { compose } from 'recompose';
import { AuthUserContext, withAuthorization, withEmailVerification } from '../Session';


const Itinerary = () => {
  return (
    <AuthUserContext.Consumer>
      {authUser => {
        return (
          <div className="page itinerary">
            {authUser.inviteData.sebet && (
              <>
                <div className="itinerary-day alt-photo">
                  <div className="itinerary-description">
                    <span className="gold-pill">Thanksgiving</span>
                    <h1 className="title">Day 1</h1>
                    <p className="date">November 23rd</p>
                    <p className="time">6pm - 10pm</p>
                    <p className="description">Indulge in a unique Thanksgiving experience at Nizuc, Mexico, where traditional flavors meet exotic luxury. Delight in a sumptuous dinner blending local ingredients with classic holiday fare amidst the breathtaking backdrop of turquoise waters and lush tropical surroundings.</p>
                  </div>
                  <div className="itinerary-photo">
                    <img src="tg.jpg" />
                  </div>
                </div>
                <div className="itinerary-day">
                  <div className="itinerary-description">
                    <span className="gold-pill">Erev Shabbat</span>
                    <h1 className="title">Day 2</h1>
                    <p className="date">November 24th</p>
                    <p className="time">Candle Lighting 5:46pm</p>
                    <p className="description">Join us for Minha and Arbit at 5:45pm followed by an authentic Shabbat dinner at 6:45pm in the Siaan Kaan ballroom.</p>
                  </div>
                  <div className="itinerary-photo">
                    <img src="sh.jpg" />
                  </div>
                </div>
                <div className="itinerary-day alt-photo">
                  <div className="itinerary-description">
                    <span className="gold-pill">Sebet</span>
                    <h1 className="title">Day 3</h1>
                    <p className="date">November 25th</p>
                    <p className="time">9am - 2pm</p>
                    <p className="description">Join us for Shahrit in the hotel at 9am promptly. Sebet will follow Shahrit at 12:00 noon. A class will be given by Rabbi Tawil at 4:30pm. Minha will be at 5:20pm.</p>
                  </div>
                  <div className="itinerary-photo">
                    <img src="shabbat-table.jpg" />
                  </div>
                </div>
              </>
            )}
            <div className="itinerary-day">
              <div className="itinerary-description">
                <span className="gold-pill">Welcome Dinner</span>
                <h1 className="title">{authUser.inviteData.sebet ? 'Day 4' : 'Day 1'}</h1>
                <p className="date">November 26th</p>
                <p className="time">7pm - 10pm</p>
                <p className="description">Upon arrival, guests will be treated to a warm welcome dinner at Terra Nostra. Sunday night's celebration will include dinner and dancing, with an Arabian twist to kick off the weekend's festivities. Cocktail attire…</p>
              </div>
              <div className="itinerary-photo">
                <img src="img1.svg" />
              </div>
            </div>

            <div className="itinerary-day alt-photo">
              <div className="itinerary-description">
                <span className="gold-pill">Beach Party</span>
                <h1 className="title">{authUser.inviteData.sebet ? 'Day 5' : 'Day 2'}</h1>
                <p className="date">November 27th</p>
                <p className="time">2pm - 6pm</p>
                <p className="description">Monday's beach party will take place on the Nizuc beach, where the “rose all day” theme will have us all dancing. Guests will be treated to a beautiful lunch on the sand, and will be most comfortable in beach casual attire.</p>
              </div>
              <div className="itinerary-photo">
                <img src="img1.jpg" />
              </div>
            </div>

            <div className="itinerary-day">
              <div className="itinerary-description">
                <span className="gold-pill">Wedding</span>
                <h1 className="title">{authUser.inviteData.sebet ? 'Day 6' : 'Day 3'}</h1>
                <p className="date">November 28th</p>
                <p className="time">All day</p>
                <p className="description">The wedding day will begin at the Akan Terrace for champagne hour. The ceremony will then commence at 5pm on Nizuc Beach, a picturesque location for the most romantic evening. Following the ceremony, guests will make their way to the Siaan Kaan ballroom for the black tie reception, where guests will be treated to dinner and dancing.</p>
              </div>
              <div className="itinerary-photo">
                <img src="img2.jpg" />
              </div>
            </div>

            <Prefooter />
          </div>
        )
      }}
    </AuthUserContext.Consumer>
  )
};


const condition = authUser => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(Itinerary);
