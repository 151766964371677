import React, { useState } from 'react';
import { compose } from 'recompose';
import "./home.scss";
import { AuthUserContext, withAuthorization, withEmailVerification } from '../Session';
import Messages from '../Messages';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import Prefooter from '../Prefooter';


const HomePage = () => {
  const [email, setEmail] = useState("")

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  var radio_props = [
    { label: 'yes', value: 1 },
    { label: 'no', value: 0 }
  ];
  return (
    <AuthUserContext.Consumer>
      {authUser => {
        /* const [fullName, setFullName] = useState(authUser.username)

        const handleChange = (event) => {
          setFullName(event.target.value);
        }; */

        return (
          <div className="border">

            <div className="container">
              <div className="pad">
                <div className="gold">Welcome to Wedding Of</div>
              </div>

              <div className="image-set" >

                <div className="logo">
                  <h1>Camille & Jack</h1>
                </div>

                <picture className="splash-photo">
                  <img className='one' src="/beach.jpg" />
                </picture>

              </div>




              <div className="set-2">

                {/* <img className="two" src="/two.jpg" /> */}


                <div className="column">
                  {/* <div className="title-set">
              <div className="logo">
                <img className="title2" src="/Hey.svg" />
              </div>
              <img className="gold-graphic" src="/Vector 1.svg" />
            </div> */}


                  <p>With the help of Hashem, Camille and Jack’s wedding will take place on November 28, 2023 at The Nizuc Resort and Spa.
                    We are so excited to host our friends and family for this amazing celebration.
                    All information regarding our wedding is available here.
                    Guests are kindly welcomed for any or all of this trip.
                    We can’t wait to celebrate, and couldn’t think of better people to celebrate with.</p>

                  <Link to={ROUTES.ITINERARY} className="button button--white">Explore Itinerary</Link>
                </div>

              </div>

              <Prefooter />
            </div>
          </div>
        )
      }}
    </AuthUserContext.Consumer>
  )
};

const condition = authUser => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(HomePage);
