import React from 'react';
import { compose } from 'recompose';
import "./flights.scss";
import { AuthUserContext, withAuthorization, withEmailVerification } from '../Session';
import axios from 'axios';
import { useState, useEffect } from 'react';
import Flight from './Flight';
import Prefooter from '../Prefooter';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
});

const oneWayFlights = (itineraries, direction, outboundKey = '') => {
  const seenOutbounds = [];
  const seenOutboundKeys = [];
  itineraries.forEach(itinerary => {

    console.log("Itinerary: ", itinerary)

    if (outboundKey !== '' && itinerary.slices[0].key !== outboundKey) {
      return;
    }
    let slice = itinerary.slices[direction]


    slice.price = itinerary.total_amount
    slice.link = itinerary.bookingData.link

    slice.key = slice.segments.map(segment => {
      return `${segment.operating_carrier.iata_code} ${segment.operating_carrier_flight_number}`
    }, '').join(',')
    if (!seenOutboundKeys.includes(slice.key)) {
      seenOutbounds.push(slice);
      seenOutboundKeys.push(slice.key);
    }
  })

  return seenOutbounds;
}

const FlightSearchForm = ({ setIsLoading, setFlights, setOutbounds, setCurrentOutbound, setCurrentInbound, authUser }) => {
  const searchParams = new URLSearchParams(window.location.search);
  const departureDate = searchParams.get('departureDate') || authUser.inviteData.sebet ? '2023-11-23' : '2023-11-26';
  const returnDate = searchParams.get('returnDate') || '2023-11-29';
  const adults = searchParams.get('adults') || 1;
  const children = searchParams.get('children') || 0;
  const infants = searchParams.get('infants') || 0;
  const travelClass = searchParams.get('travelClass') || 'ECONOMY';

  const [formData, setFormData] = useState({
    departureDate,
    returnDate,
    adults,
    children,
    infants,
    travelClass,
  });

  useEffect(() => {
    setIsLoading(true)
    const url = `https://api.nizucwedding.com/results2?departureDate=${formData.departureDate}&returnDate=${formData.returnDate}&adults=${formData.adults}&children=${formData.children}&infants=${formData.infants}&travelClass=${formData.travelClass}`;
    axios.get(url)
      .then(response => {
        const locationUrl = new URL(window.location.href);
        for (const key in formData) {
          locationUrl.searchParams.set(key, formData[key]);
        }
        window.history.pushState({}, '', locationUrl);
        console.log("THE RESPONSE: ", response.data)
        setFlights(response.data);
        setOutbounds(oneWayFlights(response.data, 0))
        setIsLoading(false)
        setCurrentInbound({})
        setCurrentOutbound({})
      }).catch((err) => {
        console.log(err.response)
      })
  }, [formData]);

  return (
    <form className="theForm" onSubmit={(e) => e.preventDefault()}>
      <div className="contents">
        <div className="row">
          <div className="column third">
            <label htmlFor="departureDate">Departure Date</label>
            <input
              type="date"
              id="departureDate"
              name="departureDate"
              className="request"
              value={formData.departureDate}
              min="2023-11-20"
              max="2023-11-30"
              onChange={(event) => {
                setFormData({ ...formData, departureDate: event.target.value })
                setCurrentOutbound({})
                setCurrentInbound({})
              }}
            />
          </div>
          <div className="column third">
            <label htmlFor="returnDate">Return Date</label>
            <input
              type="date"
              id="returnDate"
              name="returnDate"
              className="request"
              value={formData.returnDate}
              min="2023-11-26"
              max="2023-12-06"
              onChange={(event) => {
                setFormData({ ...formData, returnDate: event.target.value })
                setCurrentInbound({})
              }}
            />
          </div>
          <div className="column third">
            <label htmlFor="travelClass">Travel Class</label>
            <select
              id="travelClass"
              name="travelClass"
              className="select request2"
              value={formData.travelClass}
              onChange={(event) => setFormData({ ...formData, travelClass: event.target.value })}
            >
              <option value="ECONOMY">Economy</option>
              <option value="BUSINESS">Business</option>
            </select>
          </div>
          <div className="column third">
            <label htmlFor="adults">Adults</label>
            <input
              type="number"
              id="adults"
              name="adults"
              className="request"
              max="9"
              value={formData.adults}
              onChange={(event) => setFormData({ ...formData, adults: event.target.value })}
            />
          </div>

          <div className="column third">
            <label htmlFor="children">Children</label>
            <input
              className="request"
              type="number"
              id="children"
              name="children"
              max="9"
              value={formData.children}
              onChange={(event) => setFormData({ ...formData, children: event.target.value })}
            />
          </div>
          <div className="column third">
            <label htmlFor="infants">Infants</label>
            <input
              className="request2"
              type="number"
              id="infants"
              name="infants"
              max="9"
              value={formData.infants}
              onChange={(event) => setFormData({ ...formData, infants: event.target.value })}
            />
          </div>
        </div>
      </div>
    </form>
  )
}

const Flights = () => {
  const [getFlights, setFlights] = useState([]);
  const [getOutbounds, setOutbounds] = useState([]);
  const [getCurrentOutbound, setCurrentOutbound] = useState({})
  const [getInbounds, setInbounds] = useState([]);
  const [getCurrentInbound, setCurrentInbound] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (getCurrentOutbound.key) {
      setInbounds(oneWayFlights(getFlights, 1, getCurrentOutbound.key))
    } else {
      setInbounds([])
      setCurrentInbound({})
    }
  }, [getCurrentOutbound.key])

  return (
    <AuthUserContext.Consumer>
      {authUser => (
        <div className="page">
          <div className="theFlex">
            <div style={{ backgroundImage: `url(/back-2.svg)`, backgroundRepeat: "no-repeat", paddingLeft: 14.36, paddingTop: 16.57 }}>
              <div style={{ backgroundImage: `url(/back-1.svg)`, backgroundRepeat: "no-repeat" }}>
                <img className="background" src="/background.svg" />
                <div className="titleContainer">
                  <h1>Flights</h1>
                  <p className="description"> The main event is planned to take place on a Tuesday, November 28<sup>th</sup> 2023. Festivities will be ongoing from November 26<sup>th</sup> to November 28<sup>th</sup>. 
                  {authUser.inviteData.sebet ? " The Sebet will take place on November 25th. It is kindly suggested that guests consider arriving on November 23rd and departing on November 29th to fully enjoy the occasion." :
                   "It is kindly suggested that guests consider arriving on November 26th and departing on November 29th to fully enjoy the occasion."}
                  </p>
                </div>
                <FlightSearchForm setIsLoading={setIsLoading} setFlights={setFlights} setOutbounds={setOutbounds} setCurrentInbound={setCurrentInbound} setCurrentOutbound={setCurrentOutbound} authUser={authUser} />

                {isLoading ? <div className="loading">Loading...</div> : (
                  <div className="flights">
                    <div className="fancy">
                      <img className="sticker-1" src="el_plane.svg" />
                      <p>New York (all airports) to Cancun</p>
                      <img className="sticker-2" src="el_plane_2.svg" />
                      <hr className="line" />
                    </div>

                    <div className="center">
                      <p>{new Date(getOutbounds[0]?.segments[0]?.departing_at)?.toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric" })}</p>
                    </div>

                    <article className="flight-list outbound-flights">
                      {Object.keys(getCurrentOutbound).length === 0 ? getOutbounds.map((flight, index) => (
                        <Flight
                          key={index}
                          flight={flight}
                          setCurrent={setCurrentOutbound}
                          getCurrent={getCurrentOutbound}
                          direction="outbound"
                        />
                      )) : (
                        <div className={"selected"}>
                          <p className="selected-title">Selected Flight{Object.keys(getCurrentInbound).length > 0 ? "s" : ""}</p>
                          <Flight
                            flight={getCurrentOutbound}
                            setCurrent={setCurrentOutbound}
                            getCurrent={getCurrentOutbound}
                            direction="outbound"
                            selected={true}
                          />

                          {Object.keys(getCurrentInbound).length > 0 ?

                            (
                              <>
                                <div className="center">
                                  {getInbounds.length > 0 && <p>{new Date(getInbounds[0]?.segments[0]?.departing_at)?.toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric" })}</p>}
                                </div>
                                <Flight
                                  flight={getCurrentInbound}
                                  setCurrent={setCurrentInbound}
                                  getCurrent={getCurrentInbound}
                                  direction="inobund"
                                  selected={true}
                                />
                              </>
                            )



                            : ""}
                        </div>
                      )}
                    </article>
                    <article className="flight-list inbound-flights">
                      <p className="return-title">{Object.keys(getCurrentOutbound).length > 0 && Object.keys(getCurrentInbound).length === 0 ? "Choose your returning flight" : ""}</p>
                      {Object.keys(getCurrentInbound).length === 0 ? getInbounds.map((flight, index) => (
                        <div className="dropDown">
                          <Flight
                            key={index}
                            flight={flight}
                            setCurrent={setCurrentInbound}
                            getCurrent={getCurrentInbound}
                            direction="inbound"
                          />
                        </div>

                      )) : ""}
                    </article>
                  </div>
                )}

                {Object.keys(getCurrentInbound).length > 0 && (
                  <div className="button-container">
                    <p className="total-price-label">Total price for all passengers:</p>
                    <p className="total-price">{formatter.format(getCurrentInbound.price)}</p>
                    <a href={getCurrentInbound.link} target="_blank" className="button bookNow">Book Now</a>
                  </div>
                )}
                <Prefooter />

              </div>
            </div>
          </div>
        </div>
      )}
    </AuthUserContext.Consumer>
  );
};

const condition = authUser => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(Flights);
