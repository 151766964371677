import {createContext, useEffect, useState} from "react";
export const MenuContext = createContext();

export const MenuContextProvider = ({children}) => {

    const [hamburgerOpen, setHamburgerOpen] = useState(false);


    return(
        <MenuContext.Provider value={{setHamburgerOpen, hamburgerOpen}}>
            {children}
        </MenuContext.Provider>
    )
}

export default MenuContext;