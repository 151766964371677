import React, { useState } from 'react';
import { Navigation, Scrollbar, A11y, SwiperCore, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.css';
import 'swiper/css/pagination';
import "./room.scss";



const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
});

function Room({ name, media, description, price, details, bookRoom }) {
  const [slide, setSlide] = useState(0);
  const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
  
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <p className="text">

        {isReadMore ? text.slice(0, 280) : text}
        ...
        <span onClick={toggleReadMore} className="read-or-hide">

          {isReadMore ? " Read more" : " show less"}
        </span>
      </p>
    );
  };

  const getSqFt = (text) => {
    return text.replace(/[^0-9]/g, '');
  }

  return (
    <div className="room">
      <div className="wrapper">
        <div className="room-media">
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            navigation
            pagin
            pagination={true}
            scrollbar={{ draggable: true }}
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            autoHeight={true}
            style={{
              "--swiper-pagination-color": "#FFBA08",
              "--swiper-pagination-bullet-inactive-color": "#999999",
              "--swiper-pagination-bullet-inactive-opacity": "1",
              "--swiper-pagination-bullet-size": "8px",
              "--swiper-pagination-bullet-horizontal-gap": "6px",
              "--swiper-pagination-bullet-box-shadow": "0px 0px 5px #525252"
            }}
          >
            {media.map((mediaItem, slide) => {
              return (

                <SwiperSlide className="room-media-item" key={slide}>
                  <img src={mediaItem} alt={name} />
                </SwiperSlide>
              )
            })}
          </Swiper>

            {/* <span className="indicators">
              {media.map((_, idx) => {
                return <button key={idx} onClick={null} className={slide === idx ? "indicator" : "i-indicator"}></button>
              })}
              </span>  */}
        </div>
        <div className="room-details">
          <div className="room-name">
            {name}
          </div>
          <ReadMore className="room-details">
            {details}
          </ReadMore>
          <li className="room-description">

            {description}
          </li>


          <div className="grid-container">
            <div class="grid-item">
              <div className="component">
                <img src="/bed.svg" />
                <p className="description">1 room</p>
              </div>
            </div>
            <div class="grid-item">
              <div className="component">
                <img src="/guests.svg" />
                <p className="description">{Number(details[0]) * 2} guest{Number(details[0]) * 2 == 1 ? "" : "s"}</p>
              </div>
            </div>
            <div class="grid-item">
              <div className="component">
                <img src="/square.svg" />
                <p className="description">{getSqFt(description)} sq ft</p>
              </div>
            </div>
            <div class="grid-item">
              <div className="component">
                <img src="/bed.svg" />
                <p className="description">{details.split(" ")[0]} {details.split(" ")[1]}</p>
              </div>
            </div>


          </div>

          <div className="row">
            <div className="room-action">
              <button onClick={bookRoom} className="button book"><p>Book Now <small>{formatter.format(price)} / night + taxes &amp; fees</small></p> <small></small></button>
            </div>
            <div className="room-price">

             
            </div>
          </div>


        </div>


      </div>
    </div>

  )
}

export default Room;