import React, { Component, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { SignUpLink } from '../SignUp';
import { PasswordForgetLink } from '../PasswordForget';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const SignInPage = () => (
  <div className="SignInPage">
    <img src="/logo.svg" alt="Jack & Camille" />
    <h1 className="text-center">Sign In</h1>
    <SignInPhoneForm />
    {/* <SignInForm /> */}
    {/* <PasswordForgetLink /> */}
    {/* <SignUpLink /> */}
  </div>
);

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

const ERROR_CODE_ACCOUNT_EXISTS =
  'auth/account-exists-with-different-credential';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`;

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({ error });
        console.log(error);
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === '' || email === '';

    return (
      <form onSubmit={this.onSubmit}>
        <input
          name="email"
          value={email}
          onChange={this.onChange}
          type="text"
          placeholder="Email Address"
        />
        <input
          name="password"
          value={password}
          onChange={this.onChange}
          type="password"
          placeholder="Password"
        />
        <button disabled={isInvalid} type="submit">
          Sign In
        </button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

class SignInPhoneBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null, phone: '', code: '', codeSent: false };
    this.confirmationResult = null;
  }

  componentDidMount() {
    this.applicationVerifier = new this.props.firebase.recaptchaVerifier(
      'recaptcha-container',
      {
        'size': 'invisible',
        'callback': (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // ...
        }
      }
    );
  }

  onSubmit = event => {
    const { phone, codeSent } = this.state;

    if (codeSent && this.confirmationResult) {
      this.confirmationResult.confirm(this.state.code)
        .then((result) => {
          // User signed in successfully. 
          const user = result.user;

          this.setState({ ...INITIAL_STATE });
          this.props.history.push(ROUTES.HOME);
          // ...
        }).catch((error) => {
          this.setState({ error });
          console.log(error);
          // User couldn't sign in (bad verification code?)
          // ...
        });
    } else {
      this.props.firebase
        .doSignInWithPhoneNumber(phone, this.applicationVerifier)
        .then((result) => {
          this.confirmationResult = result;
          this.setState({ ...INITIAL_STATE, codeSent: true });
        })
        .catch(error => {
          this.setState({ error });
          console.log(error);
        });
    }

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      if (this.state.code.length === 6) {
        this.onSubmit(event);
      }
    });
  };

  onPhoneChange = event => {
    this.setState({ phone: event });
  };

  render() {

    const { phone, error, code, codeSent } = this.state;

    const isInvalid = phone === '';

    return (
      <form onSubmit={this.onSubmit}>
        {codeSent && <>
          <p className="text-center">
            Enter the code we've sent via text message to your phone number.
          </p>
          <input
            name="code"
            value={code}
            onChange={this.onChange}
            type="text"
            placeholder="Enter Code"
            className="EnterCode"
            pattern='[0-9]*'
            maxLength="6"
            required
          />
        </>}
        {!codeSent && <>
          <p className="text-center">
            Enter your phone number to access your personalized experience.
          </p>
          <PhoneInput
            placeholder="Enter phone number"
            defaultCountry="US"
            onChange={this.onPhoneChange}
            value={phone} />
          <button className="button SignInButton" disabled={isInvalid} type="submit">
            Sign In
          </button>
        </>
        }

        <div id="recaptcha-container"></div>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

class SignInGoogleBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithGoogle()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.user.displayName,
          email: socialAuthUser.user.email,
          roles: {},
        });
      })
      .then(() => {
        this.setState({ error: null });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <button type="submit">Sign In with Google</button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

class SignInFacebookBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithFacebook()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.additionalUserInfo.profile.name,
          email: socialAuthUser.additionalUserInfo.profile.email,
          roles: {},
        });
      })
      .then(() => {
        this.setState({ error: null });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <button type="submit">Sign In with Facebook</button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

class SignInTwitterBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithTwitter()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.additionalUserInfo.profile.name,
          email: socialAuthUser.additionalUserInfo.profile.email,
          roles: {},
        });
      })
      .then(() => {
        this.setState({ error: null });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <button type="submit">Sign In with Twitter</button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);

const SignInPhoneForm = compose(
  withRouter,
  withFirebase,
)(SignInPhoneBase);

const SignInGoogle = compose(
  withRouter,
  withFirebase,
)(SignInGoogleBase);

const SignInFacebook = compose(
  withRouter,
  withFirebase,
)(SignInFacebookBase);

const SignInTwitter = compose(
  withRouter,
  withFirebase,
)(SignInTwitterBase);

export default SignInPage;

export { SignInForm, SignInGoogle, SignInFacebook, SignInTwitter };
