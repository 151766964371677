import React from 'react';

import InviteItem from './InviteItem';

import { Table, Thead, Tbody, Tr, Th } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';


const InviteList = ({
  authUser,
  invites,
  onEditInvite,
  onRemoveInvite,
}) => (
  <Table>
    <Thead>
      <Tr>
        <Th>Name</Th>
        <Th>Phone Number</Th>
        <Th>Attending</Th>
        <Th>Sebet</Th>
        <Th>Rooms</Th>
        <Th>Arrival Date</Th>
        <Th>Departure Date</Th>
        <Th>Flight Info</Th>
        <Th>Nizuc Reservation Number</Th>
        <Th>Actions</Th>
      </Tr>
    </Thead>
    <Tbody>
      {invites.map(invite => (
        <InviteItem
          authUser={authUser}
          key={invite.uid}
          invite={invite}
          onEditInvite={onEditInvite}
          onRemoveInvite={onRemoveInvite}
        />
      ))}
    </Tbody>
  </Table>
);

export default InviteList;
