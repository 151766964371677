export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const FLIGHTS = '/flights';
export const ITINERARY = '/itinerary';
export const PACKING = '/packing';
export const HOTEL = '/hotel';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/pw-forget';
export const ADMIN = '/admin';
export const ADMIN_DETAILS = '/admin/:id';
export const INVITES = '/invites';
export const INVITE_DETAILS = '/invite/:id';
export const ENTER_CODE = '/enter-code';
export const RSVP = '/rsvp';