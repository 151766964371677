import React from 'react';
import SignOutButton from '../SignOut';
import { AuthUserContext } from '../Session';

const RequestInvitePage = () => {
  const message = encodeURI(`Hi, I can't access the wedding website from this number

My name is:`)
  return (
    <AuthUserContext.Consumer>
      {authUser => (
        <div style={{margin: 10, fontSize: 16}}>
          <h1>Welcome</h1>
          <p>We could not find an invitation for the number you've entered. If you feel this is an error please send a text to <a href={`sms:+17328023500&body=${message}`}>us</a> to resolve this promptly.</p>
          <p>Please note access is limited to invitees as we are limited with the number of guests we can accomodate.</p>
          <SignOutButton />
        </div>
      )}
    </AuthUserContext.Consumer>
  )
};

export default RequestInvitePage;
