import React, { Component } from 'react';
import PhoneInput from 'react-phone-number-input';
import { Tr, Td } from 'react-super-responsive-table';

class InviteItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      editName: this.props.invite.fullName,
      editPhoneNumber: this.props.invite.phoneNumber,
      editAttending: this.props.invite.attending,
      editRooms: this.props.invite.rooms,
      editArrivalDate: this.props.invite.arrivalDate,
      editDepartureDate: this.props.invite.departureDate,
      editFlightInfo: this.props.invite.flightInfo,
      editSebet: this.props.invite.sebet,
      editReservationNumber: this.props.invite.nizucReservation,
    };
  }

  onToggleEditMode = () => {
    this.setState(state => ({
      editMode: !state.editMode,
      editName: this.props.invite.fullName,
      editPhoneNumber: this.props.invite.phoneNumber,
      editAttending: this.props.invite.attending,
      editRooms: this.props.invite.rooms,
      editArrivalDate: this.props.invite.arrivalDate,
      editDepartureDate: this.props.invite.departureDate,
      editFlightInfo: this.props.invite.flightInfo,
      editSebet: this.props.invite.sebet,
      editReservationNumber: this.props.invite.nizucReservation,
    }));
  };

  onChangeEditName = event => {
    this.setState({ editName: event.target.value });
  };

  onSaveEditText = () => {
    this.props.onEditInvite(this.props.invite, this.state.editName, this.state.editPhoneNumber, this.state.editAttending, this.state.editRooms, this.state.editArrivalDate, this.state.editDepartureDate, this.state.editFlightInfo, this.state.editSebet, this.state.editReservationNumber);

    this.setState({ editMode: false });
  };

  render() {
    const { authUser, invite, onRemoveInvite } = this.props;
    const { editMode, editName, editPhoneNumber, editAttending, editRooms, editArrivalDate, editDepartureDate, editFlightInfo, editSebet, editReservationNumber } = this.state;

    return (
      <Tr>
        <Td>
          {editMode ? (
            <input
              type="text"
              value={editName}
              onChange={this.onChangeEditName}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  this.onSaveEditText();
                }
              }}
            />
          ) : (
            <span>
              {invite.fullName}
            </span>
          )}
        </Td>
        <Td>
          {editMode ? (
            <PhoneInput
              placeholder="Enter phone number"
              value={editPhoneNumber}
              onChange={editPhoneNumber => this.setState({ editPhoneNumber })}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  this.onSaveEditText();
                }
              }}
            />
          ) : (
            <span>
              {invite.phoneNumber}
            </span>
          )}
        </Td>
        <Td>
          {editMode ? (
            <span>
              <label>
                <input type="radio" value="Yes" name={`choice-${invite.uid}`} checked={editAttending === 'Yes'} onChange={e => this.setState({ editAttending: 'Yes' })} />
                <span>Yes</span>
              </label>
              <label>
                <input type="radio" value="No" name={`choice-${invite.uid}`} checked={editAttending === 'No'} onChange={e => this.setState({ editAttending: 'No' })} />
                <span>No</span>
              </label>
            </span>
          ) : (
            <span>
              {invite.attending || "No"}
            </span>
          )}
        </Td>
        <Td>
          {editMode ? (
            <span>
              <label>
                <input type="radio" value="Yes" name={`choice-${invite.uid}-2`} checked={editSebet === true} onChange={e => this.setState({ editSebet: true })} />
                <span>Yes</span>
              </label>
              <label>
                <input type="radio" value="No" name={`choice-${invite.uid}-2`} checked={editSebet === false} onChange={e => this.setState({ editSebet: false })} />
                <span>No</span>
              </label>
            </span>
          ) : (
            <span>
              {invite.sebet ? "Yes" : "No"}
            </span>
          )}
        </Td>
        <Td>
          {editMode ? (
            <input
              type="text"
              value={editRooms}
              onChange={e => this.setState({ editRooms: e.target.value })}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  this.onSaveEditText();
                }
              }}
            />
          ) : (
            <span>
              {invite.rooms}
            </span>
          )}
        </Td>
        <Td>
          {editMode ? (
            <input
              type="date"
              value={editArrivalDate}
              onChange={e => this.setState({ editArrivalDate: e.target.value })}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  this.onSaveEditText();
                }
              }}
            />
          ) : (
            <span>
              {invite.arrivalDate}
            </span>
          )}
        </Td>
        <Td>
          {editMode ? (
            <input
              type="date"
              value={editDepartureDate}
              onChange={e => this.setState({ editDepartureDate: e.target.value })}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  this.onSaveEditText();
                }
              }}
            />
          ) : (
            <span>
              {invite.departureDate}
            </span>
          )}
        </Td>
        <Td>
          {editMode ? (
            <textarea
              onChange={e => this.setState({ editFlightInfo: e.target.value })}
              value={editFlightInfo}
            ></textarea>
          ) : (
            <span>
              <pre>{invite.flightInfo}</pre>
            </span>
          )}
        </Td>
        <Td>
          {editMode ? (
            <input
              type="text"
              value={editReservationNumber}
              onChange={e => this.setState({ editReservationNumber: e.target.value })}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  this.onSaveEditText();
                }
              }}
            />
          ) : (
            <span>
              {invite.nizucReservation}
            </span>
          )}
        </Td>
        <Td>
          {authUser?.roles?.ADMIN === "ADMIN" && (
            <span>
              {editMode ? (
                <span>
                  <button onClick={this.onSaveEditText}>Save</button>
                  <button onClick={this.onToggleEditMode}>Reset</button>
                </span>
              ) : (
                <button onClick={this.onToggleEditMode}>Edit</button>
              )}
            </span>
          )}

          {!editMode && (
            <button
              type="button"
              onClick={() => onRemoveInvite(invite.uid)}
            >
              Delete
            </button>
          )}
        </Td>
      </Tr>
    );
  }
}

export default InviteItem;
