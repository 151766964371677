import React, { useState } from 'react';
import Room from "./Room";
import "./hotel.scss";
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import Prefooter from '../Prefooter';
import { AuthUserContext } from '../Session';


function Hotel(props) {
  const [showDialog, setShowDialog] = useState(false);
  const bookRoom = (e) => {
    e.preventDefault();
    setShowDialog(true);
  }
  const rooms = [
    {
      "Room Name": "OCEAN VIEW DELUXE KING",
      "Group Code": "cam",
      "Price": 523,
      "Room Description": "One king bed with private terrace and ocean views, 807 Sq Ft",
      "Room Details": "1 King\nRoom Amenities\nComplimentary high speed internet in room\niPod docking station\nMinibar\nNon-smoking\nOcean view\nPets allowed\nSafe box\nSitting area\nOne king bed with private terrace and ocean views, 807 Sq Ft\nCotton linens\nThis suite is conveniently adjoined to the Penthouse (subject to availability)\nFloor-to-ceiling windows that open onto a private terrace\nBlackout curtains provide a night of comfort and tranquility\nLarge bathroom boasts a freestanding deep soaking tub with separate rainfall shower\nBath amenities include plush bathrobes, slippers, and ESPA products\nIn-suite entertainment is provided through a state-of-the-art system with flat panel televisions\nIP Avaya telephone and iPod dock station\nComplimentary wireless internet access in-suite and public areas\nMini-bar at a charge\nNespresso coffee maker",
      "Images Array": [
        "https://be.synxis.com/shs-ngbe-image-resizer/images/hotel/58283/images/room/nizuc_ocean_view_deluxe_king.jpg",
        "https://be.synxis.com/shs-ngbe-image-resizer/images/hotel/58283/images/room/nizuc_ocean_view_deluxe_king2.jpg",
        "https://be.synxis.com/shs-ngbe-image-resizer/images/hotel/58283/images/room/nizuc_ocean_view_deluxe_king_bathroom_resized.jpg"
      ]
    },
    {
      "Room Name": "OCEAN VIEW DELUXE DOUBLE",
      "Group Code": "cam",
      "Price": 523,
      "Room Description": "Two Queen Beds with private terrace and spectacular views of the ocean and lush landscape- 807 sq ft",
      "Room Details": "2 Queen\nRoom Amenities\nComplimentary high speed internet in room\niPod docking station\nMinibar\nNon-smoking\nOcean view\nPets allowed\nSafe box\nSitting area\nTwo Queen Beds with private terrace and spectacular views of the ocean and lush landscape- 807 sq ft\nCotton linens\nThis suite conveniently connects to a Master Suite (subject to availability)\nFloor-to-ceiling windows that open onto a private terrace\nBlackout curtains provide a night of comfort and tranquility\nLarge bathroom boasts a freestanding deep soaking tub with separate rainfall shower\nBath amenities include plush bathrobes, slippers, and ESPA products\nIn-suite entertainment is provided through a state-of-the-art system with flat panel televisions\nIP Avaya telephone and iPod dock station\nComplimentary wireless internet access in-suite and public areas\nMini-bar at a charge\nNespresso coffee maker",
      "Images Array": [
        "https://be.synxis.com/shs-ngbe-image-resizer/images/hotel/58283/images/room/ocean_view_deluxe_double.jpg",
        "https://be.synxis.com/shs-ngbe-image-resizer/images/hotel/58283/images/room/nizuc_ocean_view_deluxe_double.jpg",
        "https://be.synxis.com/shs-ngbe-image-resizer/images/hotel/58283/images/room/nizuc_ocean_view_deluxe_double2.jpg",
        "https://be.synxis.com/shs-ngbe-image-resizer/images/hotel/58283/images/room/nizuc_ocean_view_deluxe_double_bathrooms.jpg"
      ]
    },
    {
      "Room Name": "OCEAN SUITE",
      "Group Code": "cam",
      "Price": 603,
      "Room Description": "Take in the spectacular ocean views from our lavish 937 sq ft Ocean Suite with complete comfort and privacy in an adult-only environment perfect for a romantic getaway or honeymoon.",
      "Room Details": "1 King\nRoom Amenities\nComplimentary high speed internet in room\niPod docking station\nMinibar\nNon-smoking\nOcean view\nPets allowed\nSafe box\nSitting area\nTake in the spectacular ocean views from our lavish 937 sq ft Ocean Suite with complete comfort and privacy in an adult-only environment perfect for a romantic getaway or honeymoon.\nAccomodation for adults only, up to two guests\nLavish 950 sq ft Ocean Suite, with 710 sq ft of interior space and floor-to-ceiling windows that open onto a beautiful 240 sq ft terrace boasting breathtaking views\nOne king bed draped in fine cotton linens\nPrivate terraces\nBlackout curtains provide a night of comfort and tranquility\nLarge bathroom boasts a freestanding deep soaking tub with separate rainfall shower\nBath amenities include plush bathrobes, slippers, and ESPA products\nIn-suite entertainment is provided through a state-of-the-art system with flat panel televisions in both the bedroom and the bathroom\nIP Avaya telephone and iPod dock station\nComplimentary wireless internet access in suite and public areas\nMini-bar at a charge\nNespresso coffee maker",
      "Images Array": [
        "https://be.synxis.com/shs-ngbe-image-resizer/images/hotel/58283/images/room/nizuc__ocean_suite_main_resized.jpg",
        "https://be.synxis.com/shs-ngbe-image-resizer/images/hotel/58283/images/room/nizuc__ocean_suite.jpg",
        "https://be.synxis.com/shs-ngbe-image-resizer/images/hotel/58283/images/room/nizuc_ocean_suite1_resized.jpg",
        "https://be.synxis.com/shs-ngbe-image-resizer/images/hotel/58283/images/room/nizuc__ocean_suite2.jpg",
        "https://be.synxis.com/shs-ngbe-image-resizer/images/hotel/58283/images/room/nizuc__ocean_suite3.jpg",
        "https://be.synxis.com/shs-ngbe-image-resizer/images/hotel/58283/images/room/ocean_suite_1.jpg",
        "https://be.synxis.com/shs-ngbe-image-resizer/images/hotel/58283/images/room/nizuc__ocean_suite_bathroom_resized.jpg"
      ]
    },
    {
      "Room Name": "OCEAN VIEW JUNIOR SUITE",
      "Group Code": "cam",
      "Price": 828,
      "Room Description": "Located just a short distance from the beaches and main pool area, features a bedroom suite set in a private tropical garden with an infinity pool and waterfall. Area: 2422 Sq Ft",
      "Room Details": "1 King\nRoom Amenities\nComplimentary high speed internet in room\niPod docking station\nMinibar\nNon-smoking\nPets allowed\nPrivate pool\nSafe box\nSitting area\nLocated just a short distance from the beaches and main pool area, features a bedroom suite set in a private tropical garden with an infinity pool and waterfall. Area: 2422 Sq Ft\nOne king bed draped in fine cotton linens with option to include one rollaway bed, up to three guests\nGarden and infinity pool views\nFloor-to-ceiling windows that open onto a private terrace\nBlackout curtains provide a night of comfort and tranquility\nLarge bathroom boasts a freestanding deep soaking tub with separate rainfall shower. It also features an outdoor nature-inspired shower\nBath amenities include plush bathrobes, slippers, and ESPA products\nIn-suite entertainment is provided through a state-of-the-art system with flat panel televisions in both the bedroom and the bathroom\nIP Avaya telephone and iPod dock station\nComplimentary wireless internet access in-suite and public areas\nMini-bar at a charge\nNespresso coffee maker\nButler service",
      "Images Array": [
        "https://be.synxis.com/shs-ngbe-image-resizer/images/hotel/58283/images/room/nizuc_private_pool_villa_resized.jpg",
        "https://be.synxis.com/shs-ngbe-image-resizer/images/hotel/58283/images/room/nizuc_private_pool_villa2_resized.jpg",
        "https://be.synxis.com/shs-ngbe-image-resizer/images/hotel/58283/images/room/nizuc_private_pool_villa3_resized20190507211216777.jpg",
        "https://be.synxis.com/shs-ngbe-image-resizer/images/hotel/58283/images/room/nizuc_private_pool_villa4_resized.jpg",
        "https://be.synxis.com/shs-ngbe-image-resizer/images/hotel/58283/images/room/nizuc_private_pool_villa_bathroom.jpg"
      ]
    },
    {
      "Room Name": "PRIVATE POOL VILLA",
      "Group Code": "cam",
      "Price": 828,
      "Room Description": "Located just a short distance from the beaches and main pool area, features a lounge pavillion connected to a luxurious bedroom suite. Area: 2744 Sq Ft",
      "Room Details": "1 King\nRoom Amenities\nComplimentary high speed internet in room\niPod docking station\nMinibar\nNon-smoking\nPets allowed\nPrivate pool\nSafe box\nSitting area\nLocated just a short distance from the beaches and main pool area, features a lounge pavillion connected to a luxurious bedroom suite. Area: 2744 Sq Ft\nOne king bed draped in fine cotton linens with option to include one rollaway bed, up to three guests\nSet in a private tropical garden with an infinity pool and waterfall\nFloor-to-ceiling windows that open onto a private terrace\nBlackout curtains provide a night of comfort and tranquility\nLarge bathroom boasts a freestanding deep soaking tub with separate rainfall shower. It also features an outdoor nature-inspired shower\nBath amenities include plush bathrobes, slippers, and ESPA products\nIn-suite entertainment is provided through a state-of-the-art system with flat panel televisions in both the bedroom and the bathroom\nIP Avaya telephone and iPod dock station\nComplimentary wireless internet access in-suite and public areas\nMini-bar at a charge\nNespresso coffee maker\nButler service",
      "Images Array": [
        "https://be.synxis.com/shs-ngbe-image-resizer/images/hotel/58283/images/room/nizuc__private_pool_pavillion_villa_main_resized.jpg",
        "https://be.synxis.com/shs-ngbe-image-resizer/images/hotel/58283/images/room/nizuc__private_pool_pavillion_villa_resized.jpg",
        "https://be.synxis.com/shs-ngbe-image-resizer/images/hotel/58283/images/room/nizuc__private_pool_pavillion_villa2_resized.jpg",
        "https://be.synxis.com/shs-ngbe-image-resizer/images/hotel/58283/images/room/nizuc__private_pool_pavillion_villa3.jpg",
        "https://be.synxis.com/shs-ngbe-image-resizer/images/hotel/58283/images/room/nizuc__private_pool_pavillion_villa4_resized.jpg",
        "https://be.synxis.com/shs-ngbe-image-resizer/images/hotel/58283/images/room/nizuc__private_pool_pavillion_villa_bathroom.jpg"
      ]
    },
    {
      "Room Name": "PRIVATE POOL PAVILLION VILLA",
      "Group Code": "cam",
      "Price": 878,
      "Room Description": "Located just a short distance from the beaches and main pool area, features a lounge pavillion connected to a luxurious bedroom suite. Area: 2744 Sq Ft",
      "Room Details": "1 King\nRoom Amenities\nComplimentary high speed internet in room\niPod docking station\nMinibar\nNon-smoking\nPets allowed\nPrivate pool\nSafe box\nSitting area\nLocated just a short distance from the beaches and main pool area, features a lounge pavillion connected to a luxurious bedroom suite. Area: 2744 Sq Ft\nOne king bed draped in fine cotton linens with option to include one rollaway bed, up to three guests\nSet in a private tropical garden with an infinity pool and waterfall\nFloor-to-ceiling windows that open onto a private terrace\nBlackout curtains provide a night of comfort and tranquility\nLarge bathroom boasts a freestanding deep soaking tub with separate rainfall shower. It also features an outdoor nature-inspired shower\nBath amenities include plush bathrobes, slippers, and ESPA products\nIn-suite entertainment is provided through a state-of-the-art system with flat panel televisions in both the bedroom and the bathroom\nIP Avaya telephone and iPod dock station\nComplimentary wireless internet access in-suite and public areas\nMini-bar at a charge\nNespresso coffee maker\nButler service",
      "Images Array": [
        "https://be.synxis.com/shs-ngbe-image-resizer/images/hotel/58283/images/room/nizuc__private_pool_pavillion_villa_main_resized.jpg",
        "https://be.synxis.com/shs-ngbe-image-resizer/images/hotel/58283/images/room/nizuc__private_pool_pavillion_villa_resized.jpg",
        "https://be.synxis.com/shs-ngbe-image-resizer/images/hotel/58283/images/room/nizuc__private_pool_pavillion_villa2_resized.jpg",
        "https://be.synxis.com/shs-ngbe-image-resizer/images/hotel/58283/images/room/nizuc__private_pool_pavillion_villa3.jpg",
        "https://be.synxis.com/shs-ngbe-image-resizer/images/hotel/58283/images/room/nizuc__private_pool_pavillion_villa4_resized.jpg",
        "https://be.synxis.com/shs-ngbe-image-resizer/images/hotel/58283/images/room/nizuc__private_pool_pavillion_villa_bathroom.jpg"
      ]
    },
    {
      "Room Name": "PENTHOUSE",
      "Group Code": "cam",
      "Price": 1018,
      "Room Description": "Located on the fifth floor of the residence area, this suite offers panoramic views of the Caribbean Sea. Area: 1765 Sq Ft",
      "Room Details": "1 King\nRoom Amenities\nComplimentary high speed internet in room\niPod docking station\nMinibar\nNon-smoking\nOcean view\nPets allowed\nPrivate pool\nSafe box\nSitting area\nLocated on the fifth floor of the residence area, this suite offers panoramic views of the Caribbean Sea. Area: 1765 Sq Ft\nOne king bed draped in fine cotton linens, option to include a rollaway bed. Up to three guests, ideal for families\nOcean and Plunge Pool views\nFloor-to-ceiling windows that open onto a private terrace\nBlackout curtains provide a night of comfort and tranquility\nLarge bathroom boasts a freestanding deep soaking tub with separate rainfall and outdoor shower\nBath amenities include plush bathrobes, slippers, and ESPA products\nIn-suite entertainment is provided through a state-of-the-art system with flat panel televisions\nIP Avaya telephone and iPod dock station\nComplimentary wireless internet access in-suite and public areas\nMini-bar at a charge\nNespresso coffee maker\nButler service",
      "Images Array": [
        "https://be.synxis.com/shs-ngbe-image-resizer/images/hotel/58283/images/room/nizuc_penthouse.jpg",
        "https://be.synxis.com/shs-ngbe-image-resizer/images/hotel/58283/images/room/nizuc_penthouse2.jpg",
        "https://be.synxis.com/shs-ngbe-image-resizer/images/hotel/58283/images/room/nizuc_penthouse3.jpg",
        "https://be.synxis.com/shs-ngbe-image-resizer/images/hotel/58283/images/room/nizuc_penthouse5.jpg"
      ]
    }
  ]
  return (
    <AuthUserContext.Consumer>
      {authUser => {
        return (
          <main class="main-content">
            <div className="hero">
              <div className="content">
                <div className="gold first">Hotel Overview</div>
                <div className="title-component">
                  <div className="logo">
                    <h1 className="title" >Hotel Nizue </h1>
                  </div>
                  <img className="background" src="/pond.jpg" />
                </div>

                <div className="row">
                  <p className="title">About this venue</p>
                  <div className="column">
                    <p>All activities are scheduled to be held at the lavish Hotel Nizuc situated in Cancun, Mexico. The nighly rates listed below are exclusively arranged for our esteemed guests. Check-in is permitted from Sunday, November 26<sup>th</sup> onwards, and check-out is open until Thursday, November 30<sup>th</sup>. It is worth noting that additional taxes and resort fees are applicable. Kindly decline the airport transfer option when booking your stay, as transportation arrangements have been made for your convenience.</p>
                    <p>Nestled in the heart of Cancun, Mexico is an opulent hotel that exudes sophistication and elegance. This magnif icent property boasts a breathtaking view of the Caribbean Sea, and its exquisite design Flawlessly blends traditional Mexican style with contemporary architectural elements. The hotel features luxurious rooms and suites that are equipped with all the modern amenities to ensure a comfortable and enjoyable stay. The impeccable service and attention to detail provided by the highly-trained staff at Nizuc is unmatched, ensuring that guests' every need is met. The hotel's lush gardens, sparkling pools, and pristine beaches provide a serene and tranquil environment that is perfect for unwinding and relaxation. Kosher dining options at Nizuc are also a delight, with an array of delectable dishes that cater to all tastes.</p>
                  </div>

                </div>


              </div>

              <div className="hotel__rooms">

                <div className="hotel__rooms__container">
                  {rooms.map((room, index) => (
                    <Room
                      key={index}
                      name={room["Room Name"]}
                      media={room["Images Array"]}
                      description={room["Room Description"]}
                      details={room["Room Details"]}
                      price={room["Price"]}
                      bookRoom={bookRoom}
                    />
                  ))}

                </div>
              </div>
              <Prefooter />
            </div>
            <dialog className="dialog dialog--payment" open={showDialog}>
              <div className="dialog__overlay"></div>
              <div className="dialog__content">
                <div className="dialog__header">
                  <h2 className="dialog__title">Attention</h2>
                  <button className="button--close" onClick={() => setShowDialog(false)}>✕</button>
                </div>
                <div className="dialog__body">
                  <p className="dialog__description"><b>Breakfast is included.</b></p>
                  <h3 className="dialog__h3">Airport transfers</h3>
                  <p className="dialog__description">Please decline the airport transfer options offered on Nizuc's website. We will provide airport transfers for all of our guests.</p>
                  <p className="dialog__description">To confirm your transfer, please enter your flight information (arrival time, departure time, and commercial flight numbers where applicable) on the RSVP section of the website.</p>
                  {authUser.inviteData.sebet && (
                    <>
                      <h3 className="dialog__h3">Sebet</h3>
                      <p className="dialog__description">If you are joining us for the Sebet, Thursday, November 23<sup>rd</sup> to Sunday, November 26<sup>th</sup>, you will have to make two reservations, one for the sebet weekend, and another for the wedding celebration. Please use the two links below to reserve your rooms. Rates are different for the two stays.</p>
                    </>
                  )}
                  {authUser.inviteData.sebet &&
                    <a href="https://be.synxis.com/?adult=1&arrive=2023-11-23&chain=10237&child=0&currency=USD&depart=2023-11-26&group=chemtob&hotel=58283&level=hotel&locale=en-US&rooms=1" className="button" target="_blank">Book Sebet Weekend (only)</a>}
                  <a href="https://be.synxis.com/?adult=1&arrive=2023-11-26&chain=10237&child=0&currency=USD&depart=2023-11-29&group=cam&hotel=58283&level=hotel&locale=en-US&rooms=1" className="button" target="_blank">Book Wedding Stay</a>
                </div>
              </div>
            </dialog>
          </main>
        )
      }}
    </AuthUserContext.Consumer>
  );
}

export default Hotel;