import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import * as serviceWorker from './serviceWorker';

import App from './components/App';
import Firebase, { FirebaseContext } from './components/Firebase';
import { MenuContextProvider } from './context/MenuContext';

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <img src='/corner.svg' style={{ position: "absolute", zIndex: -2, left: 0, top: 0 }} />
    <img src='/corner-2.svg' style={{ position: "absolute", zIndex: -2, right: 0, top: 0 }} />
    <MenuContextProvider>
      <App />
    </MenuContextProvider>

  </FirebaseContext.Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
