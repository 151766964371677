import React from 'react';
import { Link } from 'react-router-dom';
import "./footer.scss"
import { AuthUserContext } from '../Session';
import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';

const Footer = () => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <FooterAuth authUser={authUser} />
      ) : (
        <FooterNonAuth />
      )
    }
  </AuthUserContext.Consumer>
);

const FooterAuth = ({ authUser }) => (
  <div className='f-navContainer'>
    <Link to={ROUTES.HOME}>
      <img src="/b-logo.svg" alt="Jack & Camille" />
    </Link>

    <nav className="bot-nav">
      <ul>
        <li>
          <Link to={ROUTES.HOME} onClick={window.scrollTo(0, 0)}>Home</Link>
        </li>

        <li>
          <Link to={ROUTES.ITINERARY} onClick={window.scrollTo(0, 0)}>Itinerary</Link>
        </li>
        <li>
          <Link to={ROUTES.FLIGHTS} onClick={window.scrollTo(0, 0)}>Flights</Link>
        </li>
        <li>
          <Link to={ROUTES.HOTEL} onClick={window.scrollTo(0, 0)}>Hotels</Link>
        </li>
        <li>
          <Link to={ROUTES.RSVP} onClick={window.scrollTo(0, 0)}>RSVP</Link>
        </li>

        {/* <li>
          <SignOutButton />
        </li> */}
      </ul>
    </nav>
    
      <p className='copyright'>ⓒ 2023 Camille & Jack</p>
    


  </div>
);

const FooterNonAuth = () => (
  <div />
);

export default Footer;
