import React from 'react';
import { BrowserRouter as Router, Route, Redirect, matchPath } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import "./app.scss";
import Navigation from '../Navigation';
import LandingPage from '../Landing';
import RequestInvitePage from '../RequestInvite';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
import HomePage from '../Home';
import AccountPage from '../Account';
import AdminPage from '../Admin';
import Footer from '../Footer';

import * as ROUTES from '../../constants/routes';
import { AuthUserContext, withAuthentication } from '../Session';
import Flights from '../Flights';
import Hotel from '../Hotel';
import Packing from '../Packing';
import Itinerary from '../Itinerary';
import RSVP from '../RSVP';

const App = () => {
  return (
    <Router>
      <AuthUserContext.Consumer>
        {authUser => {
          const isVerified = authUser && (authUser.roles && authUser.roles.ADMIN || authUser.isInvited);
          if (!authUser) {
            return <div className="App">
              <Route exact path={ROUTES.LANDING} component={SignInPage} />
              <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
              <Route path={ROUTES.SIGN_IN} component={SignInPage} />
              <Route
                path={ROUTES.PASSWORD_FORGET}
                component={PasswordForgetPage}
              />
              <Route path={ROUTES.HOME}>
                <Redirect to={ROUTES.LANDING} />
              </Route>
              <Route path={ROUTES.ITINERARY}>
                <Redirect to={ROUTES.LANDING} />
              </Route>
              <Route path={ROUTES.FLIGHTS}>
                <Redirect to={ROUTES.LANDING} />
              </Route>
              <Route path={ROUTES.HOTEL}>
                <Redirect to={ROUTES.LANDING} />
              </Route>
              <Route path={ROUTES.PACKING}>
                <Redirect to={ROUTES.LANDING} />
              </Route>
              <Route path={ROUTES.ADMIN}>
                <Redirect to={ROUTES.LANDING} />
              </Route>
              <Route path={ROUTES.RSVP}>
                <Redirect to={ROUTES.LANDING} />
              </Route>
            </div>
          }

          if (!isVerified) {
            return <div className="App">
              <Route exact path={ROUTES.LANDING} component={RequestInvitePage} />
              <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
              <Route path={ROUTES.SIGN_IN} component={SignInPage} />
              <Route
                path={ROUTES.PASSWORD_FORGET}
                component={PasswordForgetPage}
              />
              <Route path={ROUTES.ACCOUNT} component={AccountPage} />
              <Route path={ROUTES.HOME} component={RequestInvitePage} />
              <Route path={ROUTES.ITINERARY}>
                <Redirect to={ROUTES.HOME} />
              </Route>
              <Route path={ROUTES.FLIGHTS}>
                <Redirect to={ROUTES.HOME} />
              </Route>
              <Route path={ROUTES.HOTEL}>
                <Redirect to={ROUTES.HOME} />
              </Route>
              <Route path={ROUTES.PACKING}>
                <Redirect to={ROUTES.HOME} />
              </Route>
              <Route path={ROUTES.ADMIN}>
                <Redirect to={ROUTES.HOME} />
              </Route>
              <Route path={ROUTES.RSVP}>
                <Redirect to={ROUTES.HOME} />
              </Route>
            </div>
          }

          return <div className="App">
            <div className="parent">
              <Navigation />
            </div>

            <Route exact path={ROUTES.LANDING} component={HomePage} />
            <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
            <Route path={ROUTES.SIGN_IN} component={SignInPage} />
            <Route
              path={ROUTES.PASSWORD_FORGET}
              component={PasswordForgetPage}
            />
            <Route path={ROUTES.ACCOUNT} component={AccountPage} />
            <Route path={ROUTES.PACKING} component={Packing} />
            <Route path={ROUTES.ITINERARY} component={Itinerary} />
            <Route path={ROUTES.FLIGHTS} component={Flights} />
            <Route path={ROUTES.HOTEL} component={Hotel} />
            <Route path={ROUTES.HOME} component={HomePage} />
            <Route path={ROUTES.ADMIN} component={AdminPage} />
            <Route path={ROUTES.RSVP} component={RSVP} />

            <Footer />
          </div>
        }}
      </AuthUserContext.Consumer>
    </Router>
  )
};

export default withAuthentication(App);
